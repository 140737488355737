import Bridgestone from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/bridgestone.svg'
import Continental from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/continental.svg'
import Dunlop from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/dunlop.svg'
import Falken from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/falken.svg'
import BfGoodrich from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/bfgoodrich.svg'
import Goodyear from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/goodyear.svg'
import Kleber from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/kleber.svg'
import Roadx from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/roadx.svg'
import Maxxis from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/maxxis.svg'
import Michelin from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/michelin.svg'
import Pirelli from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/pirelli.svg'
import Hankook from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/hankook.svg'
import Taurus from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/taurus.svg'
import Vredestein from '!!file-loader?name=[name].[md4:hash:hex:6].[ext]!../../../../src/common/assets/images/logo_marcas/vredestein.svg'

const logos = [
  {
    marca: 'michelin',
    src: Michelin,
  },
  {
    marca: 'pirelli',
    src: Pirelli,
  },
  {
    marca: 'goodyear',
    src: Goodyear,
  },
  {
    marca: 'bridgestone',
    src: Bridgestone,
  },
  {
    marca: 'bfgoodrich',
    src: BfGoodrich,
  },
  {
    marca: 'kleber',
    src: Kleber,
  },
  {
    marca: 'dunlop',
    src: Dunlop,
  },
  {
    marca: 'continental',
    src: Continental,
  },
  {
    marca: 'hankook',
    src: Hankook,
  },
  {
    marca: 'maxxis',
    src: Maxxis,
  },
  {
    marca: 'vredestein',
    src: Vredestein,
  },
  {
    marca: 'falken',
    src: Falken,
  },
  {
    marca: 'taurus',
    src: Taurus,
  },
  {
    marca: 'roadx',
    src: Roadx,
  },
]

export default logos
