import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import BoxStaticPageList from '../../../../src/common/components/BoxStaticPageListMarcas/BoxStaticPageListMarcas'
import SelectorContainer from '../../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../../src/common/components/FunnelSelector/SelectorSteps'
import Layout from '../../../../src/common/components/Layout'
import SearchNeumaticos from '../../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../../src/common/components/Title'
import { reset } from '../../../../src/common/context/actions/selectorActions'
import { IAppState } from '../../../../src/common/context/context'
import {
  selectIsRodi,
  selectSkipInfo,
} from '../../../../src/common/context/selectors/selectorSelectors'
import { locale, t, textWithLocale } from '../../../../src/common/i18n'
import { SeoData } from '../../../../src/common/types/Seo'
import { getImages } from '../../../../src/common/utils/imagesS3'
import route from '../../../../src/common/utils/route'
import MarcasLogo from '../../../../src/common/components/MarcasLogo/MarcasLogo'
import { MenuPrincipal } from '../../constants/menu'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import styles from './index.module.scss'
import { LinkExterno } from '../../../../src/common/types/LinkExterno'

const placeholderImage = {
  Key: '',
  url: '',
  link: '',
  alt: '',
}
const ImageFurgoneta = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/neumatics-furgoneta.jpg`
const Imageallseason = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/pneumatics-quatre-estacions-tot-l-any.jpg`
const ImageSeguro = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/seguro-neumatico-coche-rodi.jpg`
const ImageMoto = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/pneumatics-moto.jpg`
const Image4x4 = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/neumatics-4x4.jpg`
const ImageServicios = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/serveis-neumatics-cotxe.jpg`
const ImageVerano = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/pneumatics-estiu.jpg`
const ImageInvierno = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/pneumatics-hivern-neu.jpg`
const ImageTodoSobre = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/tot-sobre-el-pneumatic.jpg`
const ImageQuad = `https://${process.env.RODI_AWS_BUCKET}/landings/neumaticos/miniaturas/pneumatics-quad.jpg`

const section = [
  {
    title: `${t('landing_marcas.section2.header')}`,
    items: [
      {
        title: `${t('landing_marcas.section2.item1.title')}`,
        description: `${t('landing_marcas.section2.item1.text')}`,
        img: ImageInvierno,
        route: 'landing-invierno',
      },
      {
        title: `${t('landing_marcas.section2.item2.title')}`,
        description: `${t('landing_marcas.section2.item2.text')}`,
        img: Imageallseason,
        route: 'landing-allseason',
      },
      {
        title: `${t('landing_marcas.section2.item3.title')}`,
        description: `${t('landing_marcas.section2.item3.text')}`,
        img: ImageVerano,
        route: 'landing-verano',
      },
    ],
  },
  {
    title: `${t('landing_marcas.section1.header')}`,
    items: [
      {
        title: `${t('landing_marcas.section1.item1.title')}`,
        description: `${t('landing_marcas.section1.item1.text')}`,
        img: Image4x4,
        route: 'landing-4x4',
      },
      {
        title: `${t('landing_marcas.section1.item2.title')}`,
        description: `${t('landing_marcas.section1.item2.text')}`,
        img: ImageFurgoneta,
        route: 'landing-furgoneta',
      },
      {
        title: `${t('landing_marcas.section1.item3.title')}`,
        description: `${t('landing_marcas.section1.item3.text')}`,
        img: ImageMoto,
        route: 'landing-moto',
      },
      {
        title: `${t('landing_marcas.section1.item4.title')}`,
        description: `${t('landing_marcas.section1.item4.text')}`,
        img: ImageQuad,
        route: 'landing-quad',
      },
    ],
  },
  {
    title: `${t('landing_marcas.section4.header')}`,
    items: [
      {
        title: `${t('landing_marcas.section4.item1.title')}`,
        description: `${t('landing_marcas.section4.item1.text')}`,
        img: ImageServicios,
        route: 'neumaticos.servicios.index',
      },
      {
        title: `${t('landing_marcas.section4.item2.title')}`,
        description: `${t('landing_marcas.section4.item2.text')}`,
        img: ImageTodoSobre,
        route: 'neumaticos.todo_sobre',
      },
      {
        title: `${t('landing_marcas.section4.item3.title')}`,
        description: `${t('landing_marcas.section4.item3.text')}`,
        img: ImageSeguro,
        route: 'neumaticos.seguro',
      },
    ],
  },
]

interface Props {
  data: {
    seoData: SeoData
    linksExternos: {
      nodes: LinkExterno[]
    }
  }
}

const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('neumaticos'),
        temporada: {
          selectable: process.env.GATSBY_WEB === 'rodi',
          value: 'verano',
        },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <div>
      <div className={styles.mobile}>
        <SearchNeumaticos />
      </div>
      <div className={styles.desktop}>
        <div className={styles.selector_container}>
          <SelectorContainer showMarcas={false} origin={route('neumaticos')}>
            {({ ...props }) => (
              <SelectorForm
                showTemporada={process.env.GATSBY_WEB === 'rodi'}
                fromModal={false}
                handleSubmit={() => {
                  const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                  navigate(next.routes.desktop)
                }}
                {...props}
              />
            )}
          </SelectorContainer>
        </div>
      </div>
    </div>
  )
}

const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)

const Neumaticos = ({ data }: Props) => {
  const { linksExternos } = data
  useSelectedMenu(MenuPrincipal.NEUMATICOS)
  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )
  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
        },
      ]}>
      <div className={styles.header}>
        <Title text={t('landing_marcas.title')} />
        <Selector styles={styles} />
        <div className={styles.banner}>
          <ResponsiveSliderBanner
            desktopImages={banners_desktop}
            mobileImages={banners_mobile}
            sizesVariant={'banner_neumaticos'}
          />
        </div>
        <p>{t('landing_marcas.intro1')}</p>
        <MarcasLogo />
      </div>
      <div className={styles.header}>
        {section.map((section) => (
          <BoxStaticPageList
            key={section.title}
            title={section.title}
            items={section.items}
          />
        ))}
        {selectIsRodi() && linksExternos.nodes.length > 0 && (
          <section className={styles.linksExternosSection}>
            <h2 className={styles.linksExternosSection__heading}>
              <FormattedMessage id="landing_marcas.sectionExternalLinks.title" />
            </h2>
            <ul className={styles.linksExternosSection__list}>
              {linksExternos.nodes.map((link: LinkExterno) => (
                <li key={link.id}>
                  <a href={link[textWithLocale('url')]} target="_self">
                    {link[textWithLocale('texto')]}
                  </a>
                </li>
              ))}
            </ul>
          </section>
        )}
        <br />
        <br />
        <br />
      </div>
    </Layout>
  )
}

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}

export default Neumaticos

export const pageQuery = graphql`
  query marcasQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }

    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: "landings/neumaticos/banners/ca/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: "landings/neumaticos/banners/ca/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: "landings/neumaticos/banners/es/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: "landings/neumaticos/banners/es/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    linksExternos: allLinkExterno(sort: { order: ASC, fields: orden }) {
      nodes {
        id: id_site_neumaticos_links
        orden
        texto_es
        texto_ca
        texto_pt
        url_ca
        url_es
        url_pt
      }
    }
  }
`
